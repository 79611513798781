.container{
    width: 100%;
}
.query-input{
    margin-bottom: 5px;
    border: 1px solid #D5D3D8;
}
.custom-input{
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid grey;
}
.flag-button{
    display: flex;
    border: 2px solid #D0D0D0;
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.flag-icon{
    border-radius: 50px;
}
.active-flag{
    border: 2px solid #2196F3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.radio-trading-type{
    margin-right: 10px;
}
.stock-type{
    display: flex;
}
.price-info{
    font-size: 12px;
}
.country-radio-div{
    display: flex;
    margin-bottom: 10px;
}
.radio-country{
    margin-right: 10px;
}
.suggestions {
    position: absolute;
    background-color: white;
    border: 1px solid #999;
    list-style: none;
    margin-top: 0;
    /*max-height: 143px;*/
    overflow-y: auto;
    padding-left: 0;
    width: 300px;
    z-index: 999;
    /*width: calc(300px + 1rem);*/
}
.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: slategray;
    color: white;
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}
.button{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D0D0D0;
    /*margin-right: 5px;*/
    position: relative;
    border: none;
    cursor: pointer;
    transition: 0.25s ease;
    outline: none;
    background-color: white;
}
.button:focus{
    outline: none;
}
.active{
    background-color: #2196F3;
    color: white;
    outline: none;
}

